.s1-button {
    @apply block w-max text-center leading-none no-underline font-bold bg-primary text-white text-lg rounded-full transition-all ease-in-out duration-200 max-w-full;

    &:hover,
    &:focus,
    &:active {
        @apply bg-primary80;
    }

    &:hover,
    &:focus {
        transform: scale(1.05);
    }

    // colors ---------------------------------------------------------------------
    &--color {
        &-secondary {
            @apply bg-secondary text-white;

            &:hover,
            &:focus,
            &:active {
                @apply bg-secondary80;
            }
        }

        &-accent-light {
            @apply bg-accent-light text-dark;

            &:hover,
            &:focus,
            &:active {
                @apply bg-accent-dark;
            }
        }

        &-accent-dark {
            @apply bg-accent-dark text-white;

            &:hover,
            &:focus,
            &:active {
                @apply bg-accent-dark80;
            }
        }

    }

    // sizes ---------------------------------------------------------------------
    &--size-small {
        @apply inline-block px-4 py-2 text-sm md:text-base;
    }

    &--size-default {
        @apply inline-block px-6 py-3.5 text-base md:text-md;
    }

    &--size-large {
        @apply inline-block px-8 py-5 text-sm md:text-xl;
    }
}


