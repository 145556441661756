// Enables smooth scrolling to a section ID
@use 'styles/common' as common;

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}
html, body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

app-rich-text ol {
    list-style-type: decimal;
}

app-rich-text ul {
    list-style-type: disc;
}

// apply default styles to specific elements Those will be used, if not specified otherwise in an element.
@layer base {

    body {
        @apply font-body text-primary bg-grey-light;
    }
    main {
        @apply bg-white;
    }
    h1, h2, h3, h4, h5, h6 {
        @apply font-bold text-primary;
    }

    h1 {
        @apply text-5xl md:text-6xl lg:text-7xl ;
    }

    h2 {
        @apply text-4xl md:text-5xl lg:text-6xl;
    }

    h3 {
        @apply text-3xl md:text-4xl lg:text-5xl;
    }

    h4 {
        @apply text-2xl md:text-3xl lg:text-4xl;
    }

    h5 {
        @apply text-xl md:text-2xl;
    }

    h6 {
        @apply text-lg lg:text-xl;
    }
    .card{
        @apply bg-white shadow-md rounded-lg p-6;
    }
}

@layer utilities {
    .margins-right {
        @apply mr-0 ml-auto;
    }
}

.text-inherit {
    color: inherit;
}

.app-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.show-cmp {
    text-decoration: underline;
    cursor: pointer;
}

s1-sidebar {
    h1, h2, h3, h4, h5, h6 {
        @apply text-accent-dark;
    }
}
