@font-face {
    font-family: 'Mulish';
    src: url('/fonts/mulish/Mulish-VariableFont_wght.woff2') format('woff2-variations');
    src: url('/fonts/mulish/Mulish-VariableFont_wght.woff2') format('woff2') tech('variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}

@font-face {
    font-family: 'Mulish';
    font-style: italic;
    src: url('/fonts/mulish/Mulish-Italic-VariableFont_wght.woff2') format('woff2-variations');
    src: url('/fonts/mulish/Mulish-Italic-VariableFont_wght.woff2') format('woff2') tech('variations');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
