.s1-faq {
    @apply bg-primary text-white flex flex-col gap-4 py-8;
    &--title {
        @apply text-white font-bold text-center px-5 md:px-16 lg:px-32;
    }
    &--subtitle {
        @apply text-center px-5 md:px-16 lg:px-32 my-4;
    }

    &--list {
        @apply flex flex-col gap-4;
    }
}

.s1-faq-item {
    &--toggle {
        @apply flex justify-between items-center gap-4 py-4 w-full border-b-2 border-accent-dark px-5;

        &.is-open {
            .app-faq-item--toggle-chevron {
                @apply rotate-180;
            }
        }

        &-title {
            @apply text-white text-left flex-1 rotate-0 text-sm md:text-xl break-words;
        }
        &-chevron {
            @apply transition-transform ease-in-out duration-150;
        }
    }
    &--content {
        @apply  py-4 border-b-2 border-accent-dark px-5 text-sm md:text-base;
    }
}
