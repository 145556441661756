.s1-pagination {

    &--nav {
        @apply  bg-primary text-white;

        &:disabled {
            @apply bg-grey-dark text-dark;
        }
    }
    &--index {
        @apply bg-accent-light;

        &.is-active {
            @apply bg-accent-dark text-white;
        }
    }
}
