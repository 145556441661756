.s1-footer-recruit {
    @apply block bg-grey-dark text-primary px-5 py-8;

    &__inner-container {
        @apply flex-wrap gap-4 py-2 max-w-[1440px] mx-auto my-0 bg-transparent flex items-center justify-center md:justify-between ;

        a {
            @apply text-primary;
            font-size: 1.125rem;
            position: relative;

            &::after {
                @apply bg-primary;
                bottom: 0;
                content: "";
                height: 2px;
                left: 51%;
                opacity: 0;
                position: absolute;
                right: 51%;
                transition: opacity .4s, left .4s, right .4s;
            }

            &:hover::after {
                left: 0;
                opacity: 1;
                right: 0;
            }

            &:hover {
                @apply text-primary
            }
        }
    }

}
